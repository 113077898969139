import React, { Component } from 'react'
import { isMobile, isMobileOnly } from 'react-device-detect'

import * as El from './styles'
import left_arrow from '../../icons/left.svg'
import right_arrow from '../../icons/right.svg'
import close_icon from '../../icons/close.svg'

const IMG_INTERVAL = 3000
const TEXT_TIMEOUT = 5000

export default class Overlay extends Component {
  state = {
    textVisible: false,
    galleryVisible: false,
    slidePossible: false,

    textTimeout: false,
    imgInterval: false,
    slideTimeout: false
  }

  componentWillMount () {
    const { data } = this.props
    this.setState({
      currentPosition: -1,
      storyText: data.storyText,
      storyTitle: data.storyTitle,
      slides: data.storySlides,
      length: isMobile ? this.imagesCount(data) : data.storySlides.length
    })
  }

  imagesCount = data =>
    data.storySlides.reduce(
      (p, c, i, arr) => p + arr[i].desktopImages.length,
      0
    )

  componentDidMount () {
    if (this.props.visible) this.showStory(this.props)
    document.addEventListener('keydown', this.handleKeyboardClick)
    document.addEventListener('touchstart', this.handleTouchStart.bind(this))
    document.addEventListener('touchmove', this.handleTouchMove.bind(this))
  }

  handleTouchStart = e => {
    if (this.props.visible && this.state.slidePossible) {
      this.state.xDown = e.touches[0].clientX
    }
  }

  handleTouchMove (e) {
    if (this.props.visible && this.state.slidePossible) {
      if (!this.state.xDown) return
      let xDiff = this.state.xDown - e.touches[0].clientX
      if (xDiff > 10) this.nextImg(true)
      else if (xDiff < -10) this.prevImg(true)
      this.state.xDown = null
    }
  }

  componentWillReceiveProps (nextProps) {
    this.clearTimeouts()

    const { data, slidesDirection } = nextProps
    if (nextProps.data.storySlides !== this.props.data.storySlides) {
      if (slidesDirection) {
      } else {
      }
    }

    if (nextProps.visible) {
      document.addEventListener('keydown', this.handleKeyboardClick)
      this.showStory(nextProps)
    } else {
      document.removeEventListener('keydown', this.handleKeyboardClick)
      this.setState({ textVisible: false, galleryVisible: false })
    }
  }

  componentWillUnmount () {
    this.clearTimeouts()
    document.removeEventListener('keydown', this.handleKeyboardClick)
    document.removeEventListener('touchstart', this.handleTouchStart.bind(this))
    document.removeEventListener('touchmove', this.handleTouchMove.bind(this))
  }

  handleKeyboardClick = e => {
    if (this.props.visible && this.state.slidePossible) {
      const code = e.key
      if (code === 'ArrowLeft') this.prevImg(true)
      else if (code == 'ArrowRight') this.nextImg(true)
    }
  }

  showStory = props => {
    const { data } = props
    /* Show text, set direction */
    if (props.slidesDirection) {
      this.setState({
        textVisible: true,
        galleryVisible: false,
        slidePossible: true,
        storyText: data.storyText,
        storyTitle: data.storyTitle
      })
      /* After some time hide text, show gallery, start autorotation */
      this.state.textTimeout = setTimeout(() => {
        this.setState({
          currentPosition: -1,
          slides: data.storySlides,
          length: isMobile ? this.imagesCount(data) : data.storySlides.length
        })
        this.state.textTimeout = setTimeout(() => {
          this.setState({
            currentPosition: 0,
            textVisible: false,
            galleryVisible: true,
            imgInterval: setInterval(this.nextImg, IMG_INTERVAL)
          })
        }, TEXT_TIMEOUT)
      }, 500)
    } else {
      this.setState({
        slides: data.storySlides,
        storyTitle: data.storyTitle,
        length: isMobile ? this.imagesCount(data) : data.storySlides.length,
        textVisible: false,
        galleryVisible: true,
        currentPosition: isMobile
          ? this.imagesCount(data) - 1
          : data.storySlides.length - 1,
        slidePossible: true
      })
      setTimeout(() => {
        this.setState({
          storyText: data.storyText
        })
      }, 500)
    }
  }

  clearTimeouts = () => {
    clearTimeout(this.state.textTimeout)
    clearInterval(this.state.imgInterval)
    clearTimeout(this.state.slideTimeout)
  }

  setGalleryTimeout = () => {
    this.clearTimeouts()
    this.state.textTimeout = setTimeout(() => {
      this.state.imgInterval = setInterval(this.nextImg, IMG_INTERVAL)
    }, 1000)
    this.state.slideTimeout = setTimeout(() => {
      this.state.slidePossible = true
    }, 600)
  }

  nextImg = userAction => {
    const { currentPosition, length, textVisible, galleryVisible } = this.state
    if (currentPosition < length - 1) {
      this.setState(
        { currentPosition: textVisible ? 0 : currentPosition + 1 },
        () => {
          if (userAction) {
            this.setState({
              textVisible: false,
              galleryVisible: true,
              slidePossible: false
            })
            this.setGalleryTimeout()
          }
        }
      )
      // }
      // else if (currentPosition === 0) {
      //   this.setState({ currentPosition: 0 }, () => {
      //     if (userAction) {
      //       this.setState({
      //         textVisible: false,
      //         galleryVisible: true,
      //         slidePossible: false
      //       })
      //       this.setGalleryTimeout()
      //     }
      //   })
    } else {
      this.props.changeSlidesDirection(true)
      if (galleryVisible) this.props.nextVideo()
    }
  }

  prevImg = userAction => {
    const { currentPosition, length, textVisible, galleryVisible } = this.state
    if (currentPosition > 0) {
      this.setState(
        { currentPosition: textVisible ? length - 1 : currentPosition - 1 },
        () => {
          if (userAction) {
            this.setState({
              textVisible: false,
              galleryVisible: true,
              slidePossible: false
            })
            this.setGalleryTimeout()
          }
        }
      )
    } else if (currentPosition === 0) {
      this.props.changeSlidesDirection(false)

      // show text back
      this.setState({
        textVisible: true,
        galleryVisible: false,
        currentPosition: -1
      })

      // after 5s start autorotation
      this.state.textTimeout = setTimeout(() => {
        this.setState({
          textVisible: false,
          galleryVisible: true,
          imgInterval: setInterval(this.nextImg, IMG_INTERVAL)
        })
      }, TEXT_TIMEOUT)
    } else {
      if (galleryVisible || textVisible) this.props.prevVideo()
      this.props.changeSlidesDirection(false)
    }
  }

  getSlides = () => {
    if (isMobileOnly) {
      let pos = 0
      return this.state.slides.map((slide, i) => {
        return slide.desktopImages.map((image, k) => {
          let grid = (
            <El.Grid key={k} visible={this.state.currentPosition === pos}>
              <El.Image
                fluid={
                  slide.mobileImages && slide.mobileImages[k]
                    ? slide.mobileImages[k].fluid
                    : image.fluid
                }
                alt={`slide_${pos}`}
              />
            </El.Grid>
          )
          pos++
          return grid
        })
      })
    } else {
      return this.state.slides.map((slide, i) => (
        <El.Grid key={i} visible={this.state.currentPosition === i}>
          {slide.desktopImages.map((image, k) => (
            <El.Image key={k} fluid={image.fluid} alt={`slide_${i}`} />
          ))}
        </El.Grid>
      ))
    }
  }

  render () {
    return (
      <React.Fragment>
        <El.Overlay visible={this.props.visible}>
          <img
            className='arrowL'
            src={left_arrow}
            onClick={() => this.prevImg(true)}
            alt='previous_img'
            role='button'
          />
          <img
            className='arrowR'
            src={right_arrow}
            onClick={() => this.nextImg(true)}
            alt='next_img'
            role='button'
          />
          <img
            className='close'
            src={close_icon}
            onClick={this.props.closeOverlay}
            alt='close_slides'
            role='button'
          />
          <div className='title'>{this.state.storyTitle}</div>
        </El.Overlay>

        <El.InlineGallery visible={this.state.galleryVisible}>
          {this.getSlides()}
        </El.InlineGallery>

        <El.StoryText visible={this.state.textVisible}>
          <div>{this.state.storyText}</div>
        </El.StoryText>
      </React.Fragment>
    )
  }
}
