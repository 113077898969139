import React, { Component } from 'react'
import { isIE, isMobile } from 'react-device-detect'
import messages from '../../constants/validation_messages'

import * as El from './styles'
import Overlay from '../Overlay'

import left_arrow from '../../icons/left.svg'
import right_arrow from '../../icons/right.svg'

export default class Home extends Component {
  state = {
    isOverlayOpened: false,
    slidesDirection: true
  }

  componentDidMount () {
    this.setState(
      {
        stories: this.props.data,
        length: this.props.data.length,
        currentPosition: Math.floor(Math.random() * this.props.data.length)
      },
      () => this.fetchVideo(0)
    )
    document.addEventListener('keydown', this.handleKeyboardClick)
    document.addEventListener('touchstart', this.handleTouchStart.bind(this))
    document.addEventListener('touchmove', this.handleTouchMove.bind(this))
  }

  handleTouchStart = e => {
    if (!this.state.isOverlayOpened) {
      this.state.xDown = e.touches[0].clientX
    }
  }

  handleTouchMove (e) {
    if (!this.state.isOverlayOpened) {
      if (!this.state.xDown) return
      let xDiff = this.state.xDown - e.touches[0].clientX
      if (xDiff > 10) this.nextVideo()
      else if (xDiff < -10) this.prevVideo()
      this.state.xDown = null
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleKeyboardClick)
    document.removeEventListener('touchstart', this.handleTouchStart.bind(this))
    document.removeEventListener('touchmove', this.handleTouchMove.bind(this))
  }

  handleKeyboardClick = e => {
    const code = e.key
    if (!this.state.isOverlayOpened) {
      if (code === 'ArrowLeft') this.prevVideo()
      else if (code == 'ArrowRight') this.nextVideo()
    }
  }

  nextVideo = () => {
    const { currentPosition, length } = this.state
    const nextID = isMobile ? currentPosition : (currentPosition + 1) % length
    this.setState({ currentPosition: nextID })
  }

  prevVideo = () => {
    const { currentPosition, length } = this.state
    const prevID = isMobile
      ? currentPosition
      : (currentPosition - 1 + length) % length
    this.setState({ currentPosition: prevID })
  }

  fetchVideo = i => {
    if (this.state.stories && isMobile) {
      const { stories } = this.state
      const mobile =
        isMobile && stories[i].mobileVideo && stories[i].mobileVideo.file
      fetch(
        mobile
          ? stories[i].mobileVideo.file.url
          : stories[i].desktopVideo.file.url
      )
        .then(response => response)
        .then(data => {
          console.log(data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  getVideo = i => {
    if (this.state.stories) {
      const { stories } = this.state
      const mobile =
        isMobile && stories[i].mobileVideo && stories[i].mobileVideo.file
      const Container = mobile ? El.MobileVideo : El.DesktopVideo
      return (
        <Container
          key={i}
          autoPlay
          muted
          loop
          playsInline
          visible={this.state.currentPosition === i}
          blurred={this.state.isOverlayOpened || this.props.backgroundBlurred}
        >
          <source
            src={
              mobile
                ? stories[i].mobileVideo.file.url
                : stories[i].desktopVideo.file.url
            }
            type='video/mp4'
          />
        </Container>
      )
    }
  }
  toggleOverlay = status => {
    this.setState({ isOverlayOpened: status })
    this.props.toggleOverlay(status)
  }

  changeSlidesDirection = status => {
    this.state.slidesDirection = status
  }

  backgroundVideo = () => {
    const { currentPosition, length } = this.state
    return (
      <El.VideoContainer>
        {!isMobile && this.getVideo((currentPosition - 1 + length) % length)}
        {this.getVideo(currentPosition)}
        {!isMobile && this.getVideo((currentPosition + 1) % length)}
      </El.VideoContainer>
    )
  }

  gallery = currentPosition => {
    return (
      <Overlay
        data={this.props.data[currentPosition]}
        visible={this.state.isOverlayOpened}
        changeSlidesDirection={this.changeSlidesDirection}
        slidesDirection={this.state.slidesDirection}
        prevVideo={this.prevVideo}
        nextVideo={this.nextVideo}
        closeOverlay={() => this.toggleOverlay(false)}
      />
    )
  }

  hideIEMessage = () => {
    this.setState({ ie_message_hidden: true })
  }

  getIEMessage = () => (
    <El.IEMessage
      hidden={
        this.state.ie_message_hidden ||
        this.props.backgroundBlurred ||
        this.state.isOverlayOpened
      }
    >
      <div>{messages[this.props.locale].ie_message}</div>
      <button onClick={this.hideIEMessage}>
        {messages[this.props.locale].ie_button}
      </button>
    </El.IEMessage>
  )

  render () {
    return (
      <React.Fragment>
        {this.state.stories
          ? isIE ? this.getIEMessage() : this.backgroundVideo()
          : ''}
        {this.state.stories ? (
          <El.Container visible={this.state.isOverlayOpened}>
            {this.state.isOverlayOpened &&
              this.gallery(this.state.currentPosition)}
          </El.Container>
        ) : (
          ''
        )}

        <El.ActionBar
          visible={!this.state.isOverlayOpened && !this.props.backgroundBlurred}
        >
          <div className='lang'>
            {/* <span>
              <El.AGBLink to='/'>d</El.AGBLink>
            </span>
            {' / '}
            <span>
              <El.AGBLink to='/en'>e</El.AGBLink>
            </span>
            {' / '} */}
            <span>
              <El.AGBLink to={this.props.locale === 'en' ? '/en/agb' : '/agb'}>
                AGB
              </El.AGBLink>
            </span>
          </div>

          <div className='title'>
            <span onClick={() => this.toggleOverlay(true)}>
              {this.props.bottomText}
            </span>
          </div>

          {!isMobile && (
            <React.Fragment>
              <img
                src={left_arrow}
                onClick={this.prevVideo}
                alt='previous_video'
                role='button'
              />
              <img
                src={right_arrow}
                onClick={this.nextVideo}
                alt='next_video'
                role='button'
              />
            </React.Fragment>
          )}
        </El.ActionBar>
      </React.Fragment>
    )
  }
}
