module.exports = {
  de: {
    required: 'Pflichtfeld',
    required_max3: 'Pflichtfeld, maximal 3 Angaben',
    between10and20: 'Abholung nur zwischen 10 und 20 Uhr möglich',
    between8and20: 'Lieferung nur zwischen 8 und 20 Uhr möglich',
    email: 'Ungültige E-Mail-Adresse',
    specific_size: 'Eigene Grössen sind erst ab CHF 101.— möglich',
    rush_hour:
      'Lieferantenkosten sind erhöht bei Abendlieferungen (18–20 Uhr) auf Grund des Verkehrsaufkommens',
    ie_message: `Sie benutzen Internet Explorer. Einige Funktionen dieser Webseite
    könnten abhängig ihres Betriebsystems, nicht funktionieren. Bitte
    benutzen sie einen anderen Browser wie z.B. Google Chrome.`,
    ie_button: 'Ich habe verstanden.'
  },
  en: {
    required: 'Required field',
    required_max3: 'Required field, pick 3 or less',
    between10and20: 'Pick-up time must be between 10am and 8pm',
    between8and20: 'Delivery time must be between 8am and 8pm',
    email: 'Invalid e-mail address',
    specific_size: 'Must be more than CHF 101.—',
    rush_hour:
      'Lieferantenkosten sind erhöht bei Abendlieferungen (18–20 Uhr) auf Grund des Verkehrsaufkommens',
    ie_message: `Sie benutzen Internet Explorer. Einige Funktionen dieser Webseite
    könnten abhängig ihres Betriebsystems, nicht funktionieren. Bitte
    benutzen sie einen anderen Browser wie z.B. Google Chrome.`,
    ie_button: 'Ich habe verstanden.'
  }
}
