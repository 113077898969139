import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Home from '../components/Home'

export default class index extends React.Component {
  state = {
    overlayOpened: false,
    backgroundBlurred: false
  }

  toggleBackgroundBlur = (status) => {
    this.setState({ backgroundBlurred: status })
  }

  toggleOverlay = (status) => {
    this.setState({ overlayOpened: status })
  }

  render () {
    const { location, data, pageContext } = this.props
    const node = data.allContentfulPageHome.edges[0].node

    return (
      <Layout
        pageTitle={node.pageTitle}
        location={location}
        locale={pageContext.locale}
        overlayOpened={this.state.overlayOpened}
        toggleBackgroundBlur={this.toggleBackgroundBlur}
      >
        <Home
          data={node.stories}
          bottomText={node.bottomText}
          locale={pageContext.locale}
          backgroundBlurred={this.state.backgroundBlurred}
          toggleOverlay={this.toggleOverlay}
        />
      </Layout>
    )
  }
}

export const homeQuery = graphql`
  query($locale: String) {
    allContentfulPageHome(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          bottomText
          stories {
            storyText
            storyTitle
            storySlides {
              desktopImages {
                fluid(maxWidth: 1280, quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
              mobileImages {
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
            }
            mobileVideo {
              file {
                url
              }
            }
            desktopVideo {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
