import Img from 'gatsby-image'
import styled from 'styled-components'
import media from '../../utils/media'

export const Overlay = styled.div`
  z-index: 5;

  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 50px 1fr 50px;
  grid-template-rows: 50px 1fr 50px;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  color: white;
  font-size: 15px;
  font-weight: 400;
  font-family: inherit;
  text-align: center;
  align-items: center;

  ${media.greaterThan('sm')`
    padding: 0 70px;
    -ms-grid-columns: 70px 1fr 41.6px 41.6px;
    grid-template-columns: 70px 1fr 41.6px 41.6px;
    grid-template-areas:
    '. . . close'
    '. . . .'
    '. title arrowL arrowR';
  `};

  ${media.lessThan('sm')`
    padding: 0 10px;
    font-size: 12px;
    -ms-grid-columns: 35px 1fr 35px;
    grid-template-columns: 35px 1fr 35px;
    grid-template-areas:
    '. . close'
    '. . .'
    'arrowL title arrowR';
  `};

  & .title {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    grid-area: title;
  }

  img {
    margin: 0;
    max-width: 35px;
    cursor: pointer;
    &.close {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
      -ms-grid-row: 1;
      -ms-grid-column: 4;
      grid-area: close;
    }
    &.arrowL {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
      -ms-grid-row: 3;
      -ms-grid-column: 3;
      grid-area: arrowL;
    }
    &.arrowR {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
      -ms-grid-row: 3;
      -ms-grid-column: 4;
      grid-area: arrowR;
    }
  }

  transition-duration: 0.5s;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

export const InlineGallery = styled.div`
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  transition-duration: 0.5s;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

export const Grid = styled.div`
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 10%;
  left: 10%;
  height: 80%;
  width: 80%;

  transition-duration: 0.5s;
  opacity: ${({ visible }) => (visible ? '1' : '0')};

  img {
    padding: 0 10px !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;

    max-width: 100vw !important;
    max-height: 100vh !important;

    margin: auto !important;
    object-fit: contain !important;
  }
`

export const Image = styled(Img)`
  width: 100%;
  height: 100%;
`

export const StoryText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 1rem;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  color: white;
  font-family: inherit;
  font-weight: 400;
  & > * {
    width: 80%;
  }
  ${media.lessThan('sm')`font-size: 24px; line-height: 28px;`};
  ${media.greaterThan('sm')`font-size: 60px; line-height: 68px;`};
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);

  transition-duration: 0.5s;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`
