import styled from 'styled-components'
import { Link } from 'gatsby'
import media from '../../utils/media'

export const Container = styled.div`
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition-duration: 0.5s;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;

  background-color: black;
`

const Video = styled.video`
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  transition-duration: 0.5s;
  filter: ${({ blurred }) => (blurred ? 'blur(10px);' : 'blur(0px);')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

export const MobileVideo = styled(Video)`
  @media (min-aspect-ratio: 9/16) {
    width: 100%;
  }
  @media (max-aspect-ratio: 9/16) {
    height: 100%;
  }
`
export const DesktopVideo = styled(Video)`
  @media (min-aspect-ratio: 16/9) {
    width: 100%;
  }
  @media (max-aspect-ratio: 16/9) {
    height: 100%;
  }
`

export const ActionBar = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 50px 1fr 50px;
  grid-template-rows: 50px 1fr 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  max-width: 1800px;
  color: white;
  font-size: 15px;
  font-weight: 400;
  font-family: inherit;
  user-select: none;
  text-align: center;
  white-space: normal;
  padding: 0 70px;

  ${media.greaterThan('sm')`
    -ms-grid-columns: 115px 1fr 45px 41.6px 41.6px;
    grid-template-columns: 115px 1fr 45px 41.6px 41.6px;
    grid-template-areas:
    '. . . . .' 
    '. . . . .'
    'lang title . arrowL arrowR';  
  `};

  ${media.lessThan('sm')`
    padding: 0 20px;
    font-size: 12px;
    -ms-grid-columns: 35px 1fr 35px;  
    grid-template-columns: 35px 1fr 35px;
    grid-template-areas: 
    '. lang lang' 
    '. . .' 
    'arrowL title arrowR';
  `};

  ${media.greaterThan('1940px')`
    padding: 0;
    margin-left: calc((100% - 1800px) / 2);
  `};

  .lang {
    z-index: 4;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: lang;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      cursor: pointer;
    }

    margin-right: auto;
    ${media.lessThan('sm')`
      margin-left: auto; 
      margin-right: 10px;
    `};
  }

  .title {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    grid-area: title;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      cursor: pointer;
    }
  }

  img {
    margin: 0;
    max-width: 35px;
    cursor: pointer;
    &:nth-of-type(1) {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
      -ms-grid-row: 3;
      -ms-grid-column: 4;
      grid-area: arrowL;
    }
    &:nth-of-type(2) {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
      -ms-grid-row: 3;
      -ms-grid-column: 5;
      grid-area: arrowR;
    }
  }

  transition: opacity 0.5s, visibility 0.5s;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

export const AGBLink = styled(Link)`
  text-decoration: none;
  color: #fff;
`

export const IEMessage = styled.div`
  background-color: #000;
  color: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  flex-direction: column;
  -ms-flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  div {
    width: 50%;
  }

  button {
    margin: 30px;
    color: white;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  & > * {
    z-index: 5;
    opacity: ${({ hidden }) => (!hidden ? '1' : '0')};
    visibility: ${({ hidden }) => (!hidden ? 'visible' : 'hidden')};
  }
`
